<template>
  <div class="clause-container">
    <div
      :style="{ backgroundImage: `url(${clauseHeadBg})` }"
      class="head-container">
      多彩网服务条款
    </div>
    <div class="content-container">
      <p>
        多彩网（Manycai）主营于用户互动彩票数据分析统计平台，根据中华人民共和国法律法规，本站仅适应于中国地区合法发行的福利/体育彩票数据统计。
      </p>
      <p class="tab">
          多彩网作为开放平台，用户群有“用户”和“分析人”，分析人可以在本站发布自己每期的选号结果，此结果用于用户参考阅读，网站每天会自动进行数据统计操作，用户可以根据数据统计结果自由查阅专家数据。
      </p>
      <p class="tab">
          网站数据由第三方分析人提供，分析人发布内容仅限彩票号码，不能发布文本或图片内容，本站仅统计数据。
      </p>
      <p class="tab">
          网站所有用户群体可以自由注册中文名称和个人简介，但不得违反中国法律法规的命名方式和撰写文本内容，一经发现将直接删除帐号。违禁名称和文本内容管理条约如下：
      </p>
      <p>
        1、涉及政治名人、伟人等姓名或任何相似称呼；
      </p>
      <p>
        2、低俗、暴力、色情、脏话、攻击他人、宗教等内容；
      </p>
      <p>
        3、明显的广告、网址等。
      </p>
      <p>
        本服务内容多彩网有最终解释权。
      </p>
    </div>
  </div>
</template>

<script>
import clauseHeadBg from '@/assets/clause/clause-head-bg.png'

export default {
  data () {
    return {
      clauseHeadBg
    }
  }
}
</script>

<style lang="scss" scoped>
.clause-container {
  background-color: #FFFFFF;
  .head-container {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FAFAFA;
  }
  .content-container {
    display: flex;
    flex-direction: column;
    p {
      color: #000000;
      text-align: left;
    }
  }
}
@media (max-width: 575.98px) {
  .clause-container {
    .head-container {
      height: 65px;
      background-image: none!important;
      background: #56CCF2;
      font-weight: 500;
      font-size: 21px;
    }
    .content-container {
      padding: 25px 20px;
      // gap: 25px;
      p {
        font-size: 15px;
        margin-bottom: 25px;
      }
      p.tab {
        margin-left: 15px;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .clause-container {
    .head-container {
      height: 65px;
      background-image: none!important;
      background: #56CCF2;
      font-weight: 500;
      font-size: 21px;
    }
    .content-container {
      padding: 25px 20px;
      // gap: 25px;
      p {
        font-size: 15px;
        margin-bottom: 25px;
      }
      p.tab {
        margin-left: 15px;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .clause-container {
    margin-top: 20px;
    margin-bottom: 86px;
    border-radius: 5px;
    .head-container {
      height: 200px;
      font-weight: 500;
      font-size: 36px;
    }
    .content-container {
      padding: 50px;
      // gap: 25px;
      p {
        font-size: 15px;
        margin-bottom: 25px;
        &:last-child {margin-bottom: 0;}
      }
      p.tab {
        margin-left: 15px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .clause-container {
    margin-top: 20px;
    margin-bottom: 86px;
    border-radius: 5px;
    .head-container {
      height: 200px;
      font-weight: 500;
      font-size: 36px;
    }
    .content-container {
      padding: 50px;
      // gap: 25px;
      p {
        font-size: 15px;
        margin-bottom: 25px;
        &:last-child {margin-bottom: 0;}
      }
      p.tab {
        margin-left: 15px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .clause-container {
    margin-top: 20px;
    margin-bottom: 86px;
    border-radius: 5px;
    .head-container {
      height: 200px;
      font-weight: 500;
      font-size: 36px;
    }
    .content-container {
      padding: 50px;
      // gap: 25px;
      p {
        font-size: 15px;
        margin-bottom: 25px;
        &:last-child {margin-bottom: 0;}
      }
      p.tab {
        margin-left: 15px;
      }
    }
  }
}
</style>
